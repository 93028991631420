var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row justify-content-center my-5"},[_c('div',{staticClass:"col-10 col-lg-4 text-center"},[(_vm.linkValid == false)?[_c('h3',[_vm._v("INVALID URL")]),_c('div',[_vm._v(" This URL is already expired or invalid."),_c('br'),_vm._v(" Please resubmit your email in "),_c('a',{directives:[{name:"b-modal",rawName:"v-b-modal.modalForgotPassword",modifiers:{"modalForgotPassword":true}}],attrs:{"href":"#"}},[_vm._v("here")]),_vm._v(". ")])]:(_vm.linkValid == true)?[_c('h3',{staticClass:"mb-4"},[_vm._v("SET NEW PASSWORD")]),_c('form',{attrs:{"autocomplete":"off","novalidate":"true"},on:{"submit":function($event){$event.preventDefault();return _vm.resetPassword.apply(null, arguments)}}},[_c('MyInputFormText',{attrs:{"id":"password","label":"Password","type":"password","placeholder":"Password","classComponent":{
            'is-invalid-custom': _vm.$v.password.$error,
            'is-valid-custom': !_vm.$v.password.$invalid,
          },"invalidMessage":[
            !_vm.$v.password.required ? 'Butuh kata sandi kamu nih' : null,
            !_vm.$v.password.passwordPattern
              ? 'Kata sandi kamu terlalu lemah, coba tambahin huruf dan angka yaa'
              : null,
            !_vm.$v.password.minLength
              ? `Kata sandi kamu terlalu pendek nih, panjang teks minimum ${_vm.$v.password.$params.minLength.min}.`
              : null,
          ],"redAsterisk":"true"},model:{value:(_vm.$v.password.$model),callback:function ($$v) {_vm.$set(_vm.$v.password, "$model", $$v)},expression:"$v.password.$model"}}),_c('MyInputFormText',{attrs:{"id":"confirm-password","label":"Confirm Password","type":"password","placeholder":"Confirm Password","classComponent":{
            'is-invalid-custom': _vm.$v.confirmPassword.$error,
            'is-valid-custom': !_vm.$v.confirmPassword.$invalid,
          },"invalidMessage":[
            !_vm.$v.confirmPassword.required
              ? 'Butuh konfirmasi kata sandi kamu nih'
              : null,
            !_vm.$v.confirmPassword.sameAsPassword && _vm.$v.confirmPassword.required
              ? 'Kata sandi kamu harus cocok ya ..'
              : null,
          ],"redAsterisk":"true"},model:{value:(_vm.$v.confirmPassword.$model),callback:function ($$v) {_vm.$set(_vm.$v.confirmPassword, "$model", $$v)},expression:"$v.confirmPassword.$model"}}),_c('b-button',{staticClass:"btn-wz-proceed",attrs:{"type":"submit","block":""}},[_vm._v("SUBMIT "),(_vm.isLoading)?_c('font-awesome',{attrs:{"icon":"spinner","spin":""}}):_vm._e()],1)],1),(_vm.isSuccess)?_c('div',{staticClass:"alert alert-success text-center font-14px mt-3"},[_vm._v(" Your password has been updated! "),_c('br'),_vm._v(" "+_vm._s(`You will be redirected to homepage in ${_vm.countdown} second${_vm.countdown > 1 ? `s` : ``}`)+" ")]):_vm._e()]:_vm._e()],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }