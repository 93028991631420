<template>
  <div class="row justify-content-center my-5">
    <div class="col-10 col-lg-4 text-center">
      <template v-if="linkValid == false">
        <h3>INVALID URL</h3>
        <div>
          This URL is already expired or invalid.<br />
          Please resubmit your email in
          <a href="#" v-b-modal.modalForgotPassword>here</a>.
        </div>
      </template>
      <template v-else-if="linkValid == true">
        <h3 class="mb-4">SET NEW PASSWORD</h3>
        <form
          @submit.prevent="resetPassword"
          autocomplete="off"
          novalidate="true"
        >
          <!-- Password -->
          <MyInputFormText
            id="password"
            label="Password"
            type="password"
            v-model="$v.password.$model"
            placeholder="Password"
            :classComponent="{
              'is-invalid-custom': $v.password.$error,
              'is-valid-custom': !$v.password.$invalid,
            }"
            :invalidMessage="[
              !$v.password.required ? 'Butuh kata sandi kamu nih' : null,
              !$v.password.passwordPattern
                ? 'Kata sandi kamu terlalu lemah, coba tambahin huruf dan angka yaa'
                : null,
              !$v.password.minLength
                ? `Kata sandi kamu terlalu pendek nih, panjang teks minimum ${$v.password.$params.minLength.min}.`
                : null,
            ]"
            redAsterisk="true"
          />
          <!-- Confirm Password -->
          <MyInputFormText
            id="confirm-password"
            label="Confirm Password"
            type="password"
            v-model="$v.confirmPassword.$model"
            placeholder="Confirm Password"
            :classComponent="{
              'is-invalid-custom': $v.confirmPassword.$error,
              'is-valid-custom': !$v.confirmPassword.$invalid,
            }"
            :invalidMessage="[
              !$v.confirmPassword.required
                ? 'Butuh konfirmasi kata sandi kamu nih'
                : null,
              !$v.confirmPassword.sameAsPassword && $v.confirmPassword.required
                ? 'Kata sandi kamu harus cocok ya ..'
                : null,
            ]"
            redAsterisk="true"
          />
          <!-- Submit Button -->
          <b-button type="submit" class="btn-wz-proceed" block>SUBMIT <font-awesome v-if="isLoading" icon="spinner" spin /></b-button>
        </form>

        <div class="alert alert-success text-center font-14px mt-3" v-if="isSuccess">
          Your password has been updated! <br>
          {{ `You will be redirected to homepage in ${countdown} second${countdown > 1 ? `s` : ``}` }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import MyInputFormText from "@/components/form/MyInputFormText";
import { regex } from "@/_helpers";
import { required, minLength, helpers, sameAs } from "vuelidate/lib/validators";

// Regex for Validation Start
const passwordPattern = helpers.regex("passwordPattern", regex.passwordPattern);
// Regex for Validation End

export default {
  name: "ResetPassword",
  data() {
    return {
      password: "",
      confirmPassword: "",
      isLoading: false,
      linkValid: null, //* current forgot password URL valid / invalid state
      isSuccess: null, //* user password updated success state
      countdown: 5,
    };
  },
  components: {
    MyInputFormText,
  },
  mounted() {
    this.checkStatusResetPassword();
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
      passwordPattern,
    },
    confirmPassword: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  computed: {
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    apiKey() {
      return process.env.VUE_APP_API_KEY;
    },
    isLoggedIn() {
      return this.$store.getters.getIsLoggedIn;
    },
  },
  methods: {
    startCountdownTimer() {
      const self = this;

      var countdown = setInterval(() => {
        self.countdown -= 1;

        if(self.countdown == 0) {
          clearInterval(countdown);
          this.$router.push('/');
        }
      }, 1000);

    },
    async checkStatusResetPassword() {
      const data = { key: this.$route.params.key };
      const res = await this.$api.user.checkStatusResetPassword(data);
      if (![200, 404].includes(res.status))
        return this.$helpers.toaster.make(res.data.message, "danger");
      this.linkValid = res.status === 200 ? true : false;
    },
    async resetPassword() {
      try {
        this.isLoading = true;
        this.$v.$touch();
        if (this.$v.$invalid) {
          this.isLoading = false;
          return this.$helpers.toaster.make("Please Fill the Form Correctly", "danger");
        }

        const data = {
          key: this.$route.params.key,
          password: this.password,
          confirm_password: this.confirmPassword,
        };

        const res = await this.$api.user.resetPassword(data);
        if (res.status === 200) this.isSuccess = true; //* To prompt startCountdownTimer in watch()
        else this.$helpers.toaster.make(res.data.message, "danger");
        this.isLoading = false;
      } catch (e) {
        this.isLoading = false;
        console.error(e);
      }
    },
  },
  watch: {
    isLoggedIn(val) {
      if (val) this.$router.push("/");
    },
    isSuccess(val) {
      if (val) this.startCountdownTimer();
    }
  },
};
</script>